<template>
  <ValidationProvider :rules="validationRules" :name="validationName" v-slot="{ errors, classes }" :mode="validationMode" slim>
    <md-autocomplete :name="validationName"
                     v-model="selectedResultAutocomplete"
                     :md-options="resultsAutocomplete"
                     @md-changed="searchTextChanged"
                     @md-opened="resetThenGetresultsAutocomplete"
                     @md-selected="getSelectedAutocomplete"
                     @md-closed="getSelectedValueIfExist"
                     v-bind:class="classes">
      <label>{{ label }}</label>
      <span class="md-error">{{ errors[0] }}</span>
      <template slot="md-autocomplete-item" slot-scope="{ item }">
       {{ item.Number }} - {{ item.Name }}
      </template>
    </md-autocomplete>
  </ValidationProvider>
</template>

<script>
  export default {
    data() {
      return {
        resultsAutocomplete: ['x'],
        selectedResultAutocomplete: '',
        selectedValue: null
      };
    },
    props: {
      label: {
        type: String,
        required: true
      },
      value: {
        type: Object
      },
      options: {
        type: Array,
        required: true
      },
      validationName: {
        type: String
      },
      validationRules: {
        type: String
      },
      validationMode: {
        type: String
      }
    },
    methods: {
      resetThenGetresultsAutocomplete() {
        this.resultsAutocomplete = this.getMatchingResult();
      },
      searchTextChanged(searchTerm) {
        if (!searchTerm) {
          this.selectedValue = {};
          this.selectedResultAutocomplete = '';
        }
        this.resultsAutocomplete = this.getMatchingResult(searchTerm);
      },
      getMatchingResult(searchTerm) {
        var self = this;
        return new Promise(resolve => {
          if (!searchTerm || typeof searchTerm === 'object') {
            resolve(self.options);
          } else {
            const term = searchTerm.toLowerCase();
            resolve(self.options.filter((item) => new String(item.Number.toLowerCase() + ' - ' + item.Name.toLowerCase()).includes(term)));
          }
        });
      },
      getSelectedAutocomplete(obj) {
        if (typeof this.selectedResultAutocomplete === 'object') {
          this.selectedResultAutocomplete = this.selectedResultAutocomplete.Number + ' - ' + this.selectedResultAutocomplete.Name;
        }
        this.selectedValue = obj;
      },
      getSelectedValueIfExist() {
        if (this.selectedValue && this.selectedValue.Number) {
          this.selectedResultAutocomplete = this.selectedValue.Number + ' - ' + this.selectedValue.Name;
        }
      }
    },
    watch: {
      value: {
        handler(newValue) {
          this.selectedValue = newValue;
          this.selectedResultAutocomplete = newValue && newValue.Number ? newValue.Number + ' - ' + newValue.Name : '';
        },
        immediate: true
      },
      selectedValue(newValue) {
        this.$emit('input', newValue);
      }
    }
  };
</script>
